@import url('bootstrap.min.css');
@import url("https://use.typekit.net/pld1xge.css");


html,body {
	width: 100%;
	max-width: 740px;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'museo-sans', sans-serif;
	font-weight: 400;
    font-size: 12px;
}

#tinymce .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
	text-decoration: none;
	
}

h1 {
    font-weight:900;
}
h2,h3,h4,h5,h6 {
    font-weight:700;
}
.introtext{
    font-weight: 700;
    font-size: 1.2rem;
}
.btn-secondary{
    background-image: linear-gradient(to bottom, #f29100, #da8300);
    color: #FFF;
    border-radius: .85rem;
    padding: .7rem 1rem;
}
.text-blue{
    color: #00B2C2;
}
/** ################################################## MEDIA QUERIES ################################################## **/
table {
	margin-bottom: 1em;
    border-spacing: 0px;
    border-collapse: separate;
	width: 100% !important;
    overflow-x: auto;
}
th, thead td {
	background-color: rgba(120,191,33,1);
	font-weight: 900;
}
tfoot td {
	font-weight: 900;
}
th, td {
    padding: 1em;
}
table tr {
    background-color: rgba(120,191,33,.25);
}
table tr:nth-child(even) {
   background-color: rgba(120,191,33,.1);
}



/** Small devices (landscape phones, 576px and up) **/
/** Bootstrap: -sm **/
@media (min-width: 576px) {
	html,body {	font-size: 15px; }
}

/** Medium devices (tablets, 768px and up) **/
/** Bootstrap: -md **/
@media (min-width: 768px) {
	html,body {	font-size: 16px; }
}

/** Large devices (desktops, 992px and up) **/
/** Bootstrap: -lg **/
@media (min-width: 992px) {
	html,body {	font-size: 17px; }
}

/** Extra large devices (large desktops, 1200px and up) **/
/** Bootstrap: -xl **/
@media (min-width: 1200px) {
	html,body {	font-size: 18px; }
}

/** HD devices (extra large desktops, 1540px and up) **/
@media (min-width: 1540px) {
}