@charset "utf-8";

html,body {
	max-width: none;
}
video {
  display: block;
}
img, video, picture {
  max-width: 100%;
  height:auto;
}
hr{
  border-color: #F0F5F8;
}
[alt="logo"]{
  min-width:40px;
}
.cover{
  height: 100%;
  width: 100%;
}
.bg-celebrate{
  background-image: linear-gradient(to bottom, #78bf21, #6baa1e);
}
.bg-orange{
  background-image: linear-gradient(to bottom, #f29100, #da8300);
}
.bg-blue-gradient{
  background-image: linear-gradient(to bottom, #00B2C2, #009FAE);
}
.bg-sand{
  background-image: linear-gradient(to bottom, #D0C4AD, #BDAE94);
} 
.bg-white-gradient{
  background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))
}
.bg-blue-opacity{
  background-color: rgba(0,178,194,.25);
}
.header-title{
  font-size: 1.2rem;
}
.opacity-25{
  opacity: 0.25;
}
.opacity-5{
  opacity: 0.5;
}
.opacity-75{
  opacity: 0.75;
}
.gradient-overlay:after {
  content:"";
  position: absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  background: rgb(5,28,38);
  background: linear-gradient(45deg, rgba(5,28,38,.75) 0%, rgba(88,103,110, 0) 100%);
  border-radius: 15px;
}
.homelink a{
  color: #000;
}
.homelink a:hover{
  opacity: .5;
  text-decoration: none
}
.menuicon{
  cursor: pointer;
}
.menuicon i{
  font-size: 1.6rem;
}
.menuicon:hover{
  opacity: .5;
  transition: .15s ease-in-out;
}
.toggleicon .close{
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -50%);
  font-size: .6rem;
  background-color: #212529;
  color: #FFF;
  width: .75rem;
  height: .75rem;
  text-align: center;
  line-height: .8rem;
  border-radius: 50%;
}
.toggleicon.shown .fa-magnifying-glass{
  color: rgb(120,191,33);
}
.hiddenstuff{
  position: relative;
}
.hiddenstuff:after{
  content: '';
  width: .65rem;
  height: .65rem;
  position: absolute;
  border-radius: 100%;
  background-color: #E84A1E;
  top: 10px;
  right: 10px;
  pointer-events: none;
  transform: translate(30%,-50%);
}
.profile-image {
  max-height:50px;
  max-width:50px;
  object-fit:cover;  
  border-radius:50%;
}
.author-image{
  max-height:70px;
  max-width:70px;
  object-fit:cover;  
  border-radius:10px;
}
/*
input{
  padding: 1rem;
  font-size: 1.2rem;
  background-color: #F0F5F8;
  border-radius: .9rem;
  border: none;
}
input:focus{
  outline: 2px solid rgba(5,28,38, .5);
}
*/
input{
  border: none;
  background-color: transparent;
  outline: none;
}
input::placeholder{

  color: rgba(5,28,38, .5);
}

.input-container{
    padding: 1rem;
    font-size: 1.2rem;
    background-color: #F0F5F8;
    border-radius: 0.9rem;
    border: none;
    position: relative;
}
.search-result-container{
  padding: 20px;
  position:absolute;
  top: calc(100% - 20px);
  left:0;
  background-color: #F0F5F8;
  width: 100%;
  border-radius: 0.9rem;
  z-index: 9;
}
.rounded {
  border-radius:15px!important;
}

.notification-bar-image img {
  height:4.5rem;
  width:4.5rem;
  object-fit: cover;
}
/*
.notification-icon i {
  color:white;
  line-height: 5rem;
  vertical-align: middle;
}
*/
.square-icon-wrapper {
  background:var(--danger);
  height:5rem;
  width:5rem;
  text-align: center;
  border-radius:15px;
}
.square-icon-wrapper-small {
  height:4.5rem;
  width:4.5rem;
  border-radius:15px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.notification-icon-small i {
  line-height: 4.5rem;
  vertical-align: middle;
}
.date-wrapper {
  line-height: 1;
  height:3rem;
  width:3rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  border-radius:15px;
  color:#000
}
.dnd-container .square-icon-wrapper-small{
  background-color: #f0f5f8;
  color: #000;
}
.dnd-item:nth-child(1) .square-icon-wrapper-small{
  background-image: linear-gradient(to bottom, #78bf21, #6baa1e);
  color: #FFF;
}
.dnd-item:nth-child(2) .square-icon-wrapper-small{
  background-image: linear-gradient(to bottom, #f29100, #da8300);
  color: #FFF;
}
.dnd-item:nth-child(3) .square-icon-wrapper-small{
  background-image: linear-gradient(to bottom, #00B2C2, #009FAE);
  color: #FFF;
}
.dnd-item{
  cursor: pointer;
  border: 2px dashed rgba(113,113,113, 0);
  color: #000;
  text-decoration: none;
  }
.dnd-item:hover{
  color: #000;
}
.dnd-item *{
  pointer-events:none;
}
.dnd-item a:hover{
  text-decoration: none;
}
.dnd-item.over.icon-row-item{
  background: #F0F5F8;
  box-shadow: none;
  border: 2px dashed rgba(113,113,113, .15)
}
.icon-row-item p {
  font-size:1rem;
  margin-bottom:0;
  line-height: 1.2;
}
.card.external img {
  width: 48px;
}
.toggle-remove i {
  font-size: 1.4rem;
}
.toggle-remove:hover{
  cursor: pointer;
}

.notification-item{
  margin-bottom:1rem;
}
.notification-item:last-of-type{
  margin-bottom:none;
}
.bg-danger-opacity {
  background-color: rgba(232,74,30, .25)!important;
}
.bg-success-opacity {
  background-color:rgba(120,191,33, .25)!important;
}

.icon-row h6, .icon-row-below h6, .icon-row p {
  margin-bottom:0;
}
.icon-row h6, .icon-row-below h6{
  font-size: .85rem;
}
.icon-row .custom-shadow, .icon-row-below .custom-shadow {
  box-shadow: 0 .5rem 1rem rgba(5,28,38,.15)!important;
  border-radius:15px!important;
}
.icon-row-below {
  display:none;
}

.card {
  border:none!important;
}
.card a {
  color:var(--dark)
}
.card a:hover {
  text-decoration: none;
  cursor: pointer;
  color:var(--info)
}
.card-title {
  margin-bottom:0;
}

.card-body {
  border-radius:0px 0px 15px 15px ;
}

.card-logo-top {
  position:absolute;
  top:0.75rem;
  right:2rem;
}

.internal-newsimg-wrapper{
  padding-top:40%;
  position: relative;
}
.internal-newsimg{
  position:absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  object-fit: cover;
}

.img-wrapper,
.side-swiper .img-wrapper,
.side-swiper .active .img-wrapper {
  border:4px solid transparent;
  border-radius:15px;
  overflow:hidden;
  height:70px;
  width:70px;
}
.side-swiper .active .img-wrapper{
  border:4px solid #00B2C2;
}

.slide-heading{
  font-size: 1.4rem;
  line-height: 1.15;
  font-weight: 900;
}

[data-sideswiper-item]:hover {
  cursor: pointer;
}
.side-swiper .square-icon-wrapper {
  background: var(--danger);
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 0!important;
  border:none!important;
  color: white;
  line-height: 55px;
  display: inline-block;
  vertical-align: middle;
}
.swiper-pagination{
  margin-top: 1rem;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.swiper-pagination-bullet{
  margin: 0 .3rem;
  width: 10px;
  height: 10px;
}
.swiper-pagination-bullet-active{
  background: #00B2C2!important;
}
.sidebar-title{
  font-size: 1.2rem;
}
.dnd-item-inactive{
  display: none
}

/*     STATISTIKK    */
.white-border{
  border: 4px solid #FFF;
}
.img-wrapper-profile{
  max-width: 80px;
}

.number .img-wrapper-profile .position{
  background: #FFF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  font-size: .9rem;
}
#ranking .img-wrapper-profile .position{
  left: auto;
  right: 0;
  transform: translate(50%, -50%);
}
#pallen .img-wrapper-profile {
  max-width: 200px;
  margin: 0 auto;
}
#pallen .img-wrapper-profile img{
  width: 100%;
  max-width: 140px;
}
#pallen .number .position{
  font-size: 1rem;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-weight: 900;
}
#pallen .number:nth-child(1) .white-border{
  border-color: #FFD700;
}
#pallen .number:nth-child(1) .position{
  background: #FFD700;
}

#pallen .number:nth-child(2) .white-border{
  border-color: #DDE3E6;
}
#pallen .number:nth-child(2) .position{
  background: #DDE3E6;
}

#pallen .number:nth-child(3) .white-border{
  border-color: #CD7F32;
}
#pallen .number:nth-child(3) .position{
  background: #CD7F32;
}

.crown-profile{
  position: absolute;
  max-width: 60px!important;
  top: 0;
  left: 50%;
  transform: translate(-50%,-120%);
}
.fs-1{
  font-size: .9rem;
}
.fs-2{
  font-size: 1.2rem;
}
.fs-3{
  font-size: 1.4rem;
}
@media screen and (min-width:557px ) {

}
@media screen and (min-width: 992px){
  .square-icon-wrapper-small {
    height:3rem;
    width:3rem;
  }
  h1.header-title{
    font-size: 2rem;
  }
  .sidebar-title{
    font-size: 1rem;
  }
  .side-swiper{
    padding-left: 0;
    padding-right: 0;
  }
  .icon-row-item p{
    font-size: .85rem;
  }
  .slide-heading{
    font-size: 2rem;
  }
  #pallen .img-wrapper-profile img{
    max-width: 200px;
  }
  .menuicon i{
    font-size: 1.35rem;
  }
}

@media screen and (min-width: 1024px) {

  .card.external img {
    width:auto;
  }
  

 .date-wrapper {
    height:4rem;
    width:4rem;
  
  }

  .notification-bar-image img {
    height:4rem;
    width:4rem;
    object-fit: cover;
  }
  .square-icon-wrapper-small {
    height:4rem;
    width:4rem;
    text-align: center;
    border-radius:15px;
  }
  .notification-icon-small i {
    line-height: 3rem;
  }
  
  .notification-icon-small i {
   
    line-height: 4rem;
    vertical-align: middle;
  }


  .swiper-pagination {
    right:8rem;
    margin-top:1rem;
    left:auto;
    top: auto;
    transform: none;
  }
  .swiper-pagination-bullet{
    margin-right:0.3rem;
  }
  

  
  .side-swiper {
    margin-left:-3rem;
    z-index:2;
  }


.swiper-container-2 .swiper-slide {
  height:auto;
}
}
@media screen and (min-width:1200px){
  .icon-row .custom-shadow, .icon-row-below .custom-shadow {
    box-shadow:none!important;
    border-radius:none!important;
  }
  .icon-row-item{
    box-shadow: 0 .5rem 1rem rgba(5,28,38,.15);
    border-radius:15px!important;
    transition: all .12s ease-in-out;
  }
  .icon-row-item:hover{
    cursor: pointer;
    box-shadow: none;
    background-color: #F0F5F8;
    text-decoration: none;
  }
  .col-xl-custom{
    max-width:23%;
  }
  .icon-row h6, .icon-row-below h6{
    font-size:1rem;
  }
}

@media screen and (min-width: 1540px){

    
    
}
@media (min-width: 1023px){}

@media (max-width: 767px){
  
}

/** Small devices (landscape phones, 576px and up) **/
/** Bootstrap: -sm **/
@media (max-width: 576px) {
  .header-title{
    font-size: 1rem;

  }
}

/** Medium devices (tablets, 768px and up) **/
/** Bootstrap: -md **/
@media (min-width: 768px) {
}

/** Large devices (desktops, 992px and up) **/
/** Bootstrap: -lg **/
@media (min-width: 992px) {
 
}

/** Extra large devices (large desktops, 1200px and up) **/
/** Bootstrap: -xl **/
@media (min-width: 1200px) {

 
}

/** HD devices (extra large desktops, 1540px and up) **/
@media (min-width: 1540px) {


}
@media (min-width: 1920px){
 
}
@media (max-height: 800px) {
}


/* DARK MODE CSS */
.darkmode{
  background-color: #051C26;
  color: #FFF;
}
.darkmode .card{
  color: #051C26;
}
.darkmode hr{
  opacity: .18;
}
.darkmode .icon-row-item{
  background-color:#11262E;
  color: #FFF;
}
.darkmode .dnd-container .square-icon-wrapper-small{
  background-color: #051C26;
  color: #FFF;
}
.darkmode .dnd-item.over.icon-row-item{
  background: rgba(255,255,255,.15);
  box-shadow: none;
  border: 2px dashed rgba(255,255,255, .15)
}
.darkmode .icon-row-item:hover{
  background-color: #2a5768;
}
.darkmode .bg-sand{
  color: #000;
}
.darkmode .bg-white{
  background-color: #11262E!important;
  color: #FFF;
}
.darkmode .swiper-pagination-bullet{
  background: rgba(255,255,255,.3);
}
.darkmode .icon-row .custom-shadow{
  background-color: #11262E;
}
.darkmode .bg-white .highcharts-container text{
  fill: #FFF!important;
}
.darkmode .toggleicon .close{
  background-color: #FFFFFF;
  color: #212529;
}
.darkmode .homelink a{
  color: #FFF;
}
@media screen and (min-width:1200px){
  .darkmode .icon-row .custom-shadow{
    background-color: transparent;
  }
}
.side-swiper .swiper-slide {
    max-height: 100px!important;
    height: auto !important;
}

.swiper.row .swiper-wrapper{
  flex: 0 0 75%;
    max-width: 75%;
}

.swiper-pag{
  position:relative!important;
  bottom: auto!important;
  left:auto!important;
}

.swiper-pag .swiper-pagination-bullet{
  width: auto;
  height: auto;
  border-radius: 0;
  opacity: 1;
  background: none!important;
}

.side-swiper .img-wrapper{
  border:4px solid transparent;
  border-radius:15px;
  overflow:hidden;
  height:70px;
  width:70px;
}

 .side-swiper .swiper-slide-thumb-active .img-wrapper{
  border:4px solid #00B2C2;
}

.skeleton{
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  opacity: .8;
}
.skeleton:after{


    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, #DDDBDD 0%, #cccccc 50%, #DDDBDD 100%);
    animation: shimmer 2s infinite;
    content: '';
  }

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.activities .swiper {
  overflow: visible;
}
.activities .swiper-slide {
  width: 258px !important;
  height: auto !important;
}
.activities .swiper-slide img {
  aspect-ratio: 1;
  object-fit: cover;  
}
.activities .card {
  height: 100%;
}
.activities .swiper-wrapper {
  /* padding-top: 1rem; */
  padding-bottom: 3rem;
}
.activities .swiper-scrollbar {
  background: #F0F5F8;
}
.activities .swiper-scrollbar-drag {
  background: #00B2C2;
}
.activities .add-to-calendar-button-wrap {
  display: flex;
  justify-content: flex-end;
}
.date-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  top: 14px;
  right: 20px;
  left: 14px;
  border-radius: 15px;
  width: 70px;
  height: 70px;
  aspect-ratio: 1;
  background-color: #F0F5F8;
}
.date-badge-small {
  font-size: 12px;
  font-weight: 500;
  color: #000;
}
.date-badge-small h4 {
  color: #000;
}
.darkmode .label-switch {
  color: #F0F5F8 !important;
}
input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;    
    height: 25px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    background: #828E93;
    transition: background-color .3s, box-shadow .2s;
  }

  input[type='checkbox']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform .3s ease, opacity .2s;
  }

  input[type='checkbox']:checked {
    background: #30B2C2;
    border-color: #30B2C2;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.2, .85, .32, 1.2);
  }

  input[type='checkbox'].switch {    
    width: 54px;
    min-width: 54px;
    border-radius: 30px;
  }

  input[type='checkbox'].switch:after {    
    left: 3px;
    top: 2px;
    border-radius: 50%;    
    width: 20px;
    height: 20px;
    background: #ffffff;
    transform: translateX(0);
  }

  input[type='checkbox'].switch:checked:after {
    background: #fff;    
    transform: translateX(27px);
  }
  .mixitup-controls .select-filter {
    position: relative;
  }  
  .mixitup-controls .select-filter select {
    appearance: none;
    /* safari */
    -webkit-appearance: none;
    color: #fff;
    background-color: #30B2C2;
    border-radius: 20px;
    border-color: transparent;
  }
  /* .mixitup-controls .select-filter:after {
    content: '\f078';
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 24px;    
    transform: translateY(-50%);
    pointer-events: none;
  } */

  .mixitup-controls select:focus-visible {
    outline-offset: 0px !important;
    outline: 0px !important;
  }
  .mixitup-controls .search-filter {
    border-radius: 30px;
    background-color: #F0F5F8;
  }
  .atcb-button {
    padding: 10px !important;
  }
  .eventBadge {
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    border-radius: 15px;
    padding: 5px;
    margin-top: 28px;
    position: absolute;
    z-index: 9;
    width: 260px;
    transform: translate(-50%, -50%);
  }
  .darkmode .eventBadge {
    background-color: #11262E;
  }
  .eventBadge.active {
    opacity: 1;
    visibility: visible;
  }
  .eventBadge .date-badge {
    min-width: 70px;
    height: auto;
    aspect-ratio: 1/1;
  }
  .eventBadge .add-to-calendar-button-wrap {
    opacity: 1;
    visibility: visible;
  }
  .eventBadge .btn p.small {
    font-size: 12px;
  }
  .eventBadge .card-text h6 {
    font-size: 14px;
  }
  .eventBadge .card-title p.small {
    font-size: 12px;
  }
  .calendar-wrap {
    border-radius: 15px;
    background-color: #F0F5F8;
  }
  .darkmode .calendar-wrap{
    background-color: #11262E
  }
  h3.events-control_title {
    font-size: 1.2rem;
  }
  .activities h6 {
    font-size: 16px;
  }
  .activities .card-title p.small {
    font-size: 12px;
  }
  .activities .card-body .btn p.small {
    font-size: 12px;
  }

  @media screen and (min-width:575px) {
    .activities .swiper-slide {
      width: 270px !important;      
    }
    .eventBadge {         
      width: 292px;
    }
  }
  @media screen and (min-width:768px) {
    h3.events-control_title {
      font-size: 1.75rem;
    }
    .events-control {
      font-size: 15px;
    }
    input[type='checkbox'].switch:checked:after {
      transform: translateX(42px);      
    }
    input[type='checkbox'].switch {
      width: 74px;   
    }
  
    input[type='checkbox'].switch:after {
      left: 4px;
      top: 3px;            
      width: 25px;
      height: 25px;      
    }
    input[type='checkbox'] {      
      height: 32px;      
    }
    .mixitup-controls .select-filter:after {
      right: 24px;
    }
    .activities .swiper-slide {
      width: 432px !important;      
    }
    .activities h6 {
      font-size: 1rem;
    }
    .activities .card-title p.small {
      font-size: 80%;
    }
    .date-badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 20px;      
      right: 20px; 
      left: unset;     
      border-radius: 15px;
      width: 78px;
      height: 78px;
      aspect-ratio: 1;
      background-color: #F0F5F8;
    }
    .eventBadge .date-badge {
      min-width: 78px;
    }
    .eventBadge .card-text h6 {
      font-size: 1rem;
    }
    .eventBadge .card-title p.small {
      font-size: 80%;
    }
    .eventBadge .btn p.small {
      font-size: 80%;
    }
  }
  @media screen and (min-width:1024px) {
    .eventBadge {      
      background-color: #fff;
      border-radius: 15px;
      padding: 5px;
      margin-top: 50px;
      position: absolute;
      z-index: 9;
      width: 365px;
      transform: translate(0, 0);
    }    
  }
  @media screen and (min-width:1199px) {
    .activities .swiper {
      overflow: hidden;
    }
    .activities .card .card-body .btn, .add-to-calendar-button-wrap {
      opacity: 0;
      visibility: hidden;
    }
    .activities .swiper-slide:hover {
      z-index: 100 !important;
    }
    .activities .swiper-slide:hover .card .card-body .btn, 
    .activities .swiper-slide:hover .card .card-body .add-to-calendar-button-wrap {
      opacity: 1;
      visibility: visible;
    }
    .activities .card:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #051C26;
      opacity: .5;
      visibility: visible;
      border-radius: 25px;
      transition: .3s;
    }
    .activities .card:hover:before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
    .activities .card:hover:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 7px solid #00B2C2;
      pointer-events: none;
      border-radius: 25px;
      transition: .3s;
    }
    .activities .swiper-slide.item-show .card .card-body .btn,
    .activities .swiper-slide.item-show .card .card-body .add-to-calendar-button-wrap {
      opacity: 1;
      visibility: visible;
    }
    .activities .swiper-slide.item-show .card:before {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
    .activities .swiper-slide.item-show .card:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 7px solid #00B2C2;
      pointer-events: none;
      border-radius: 25px;
      transition: .3s;
    }
    .activities .swiper-slide.item-show .card.js-first-slide:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #051C26;
      opacity: .5;
      visibility: visible;
      border-radius: 25px;
      transition: .3s;
    }
    .activities .swiper-slide.item-show .card.js-first-slide:after {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
    .activities .swiper-slide.item-show .card.js-first-slide .card-body .btn {
      opacity: 0;
      visibility: hidden;
    }    
  }
 